import React, { useContext, useEffect, useState } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNavigation,
  useNotification,
} from '@pankod/refine-core';

import {
  AntDesignOutlined,
  UserOutlined,
  SolutionOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Divider,
  Edit,
  Form,
  Input,
  ListButton,
  RcFile,
  Select,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  useFileUploadState,
  useForm,
  DatePicker,
  Row,
  Col,
  Card,
  Avatar,
  Space,
} from '@pankod/refine-antd';
import InputMask from 'react-input-mask';
import 'react-mde/lib/styles/css/react-mde-all.css';

import { IPicture, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { Role } from '../../enums/role';
import { FormRule, InputProps, InputRef } from 'antd';
import { axiosInstance } from '../../App';
import { UserType } from '../../enums/user.type';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { UserContext } from 'UserProvider';
import { DocumentType } from 'enums/document.type';
import { uuidV4 } from 'utility/uuidv4';

const { TextArea } = Input;
dayjs.extend(customParseFormat);
const { Text } = Typography;
const files: string[] = [];

export const NewsEdit: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { open: notify } = useNotification();
  const { onChange: onChangeAux } = useFileUploadState();
  const { onChange: onChangeNbki } = useFileUploadState();
  const { list } = useNavigation();
  const [userType, setUserType] = useState<UserType>();
  const [role, setRole] = useState<Role>();

  const [phone, setPhone] = useState<string>();
  let record: any;

  const {
    formProps: props,
    saveButtonProps: saveProps,
    queryResult,
    form,
  } = useForm<IUser>({
    onMutationSuccess: () => {
      return true;
    },
    successNotification: {
      description: 'Успешно',
      message: 'Новость обновлена',
      type: 'success',
    },
  });

  let formValues;

  console.log(props);

  const [publicFileList, setPublicFileList] = useState<
    UploadFile<any>[] | undefined
  >();

  useEffect(() => {
    if (props.initialValues && props.initialValues.pictures) {
      const photo: IPicture = props.initialValues.pictures;
      setPublicFileList([
        {
          uid: photo.id,
          name: photo.origin,
          url: `${process.env.REACT_APP_BACKEND_URL}${photo.path}`,
        },
      ]);
    } else {
      setPublicFileList(undefined);
    }
  }, [props.initialValues, queryResult?.data]);
  const removeFile = async (file: any) => {
    let result;
    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/media/remove_img_news',
          { file },
        );
        queryResult?.refetch();
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  return (
    <>
      {props && (
        <Edit
          headerProps={{
            subTitle: '',
            breadcrumb: <></>,
            extra: <ListButton />,
          }}
          title="Править новость"
          saveButtonProps={{ ...saveProps, children: 'Сохранить' }}
        >
          <Form {...props} layout="vertical">
            <Upload
              // disabled
              action={`${apiUrl}/media/upload`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              }}
              listType="picture-card"
              fileList={publicFileList}
              onChange={(info) => {
                if (info.file.status === 'done') {
                  console.log('Успешная загрузка', info.file.response);
                  form.setFieldValue('picture', info.file.response.id);
                  queryResult?.refetch();
                }
              }}
              maxCount={1}
              multiple={false}
              onRemove={removeFile}
              data={() => {
                const fileUuid = uuidV4();
                return { fileUuid, type: DocumentType.NEWS };
              }}
            >
              {publicFileList?.length !== 1 && 'Загрузить'}
            </Upload>
            <Form.Item name="picture" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label="Название на русском"
              name="labelRu"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Titlu în romana"
              name="labelEn"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Текст рус"
              name="textRu"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item
              label="Text ro"
              name="textEn"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea rows={3} />
            </Form.Item>
          </Form>
        </Edit>
      )}
    </>
  );
};
