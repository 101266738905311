import {
  Button,
  Form,
  Input,
  Modal,
  TextField,
  useForm,
} from '@pankod/refine-antd';
import { useOne, useUpdate } from '@pankod/refine-core';
import React from 'react';

export default function ListEdit({
  uuid,
  refetch,
  open,
  onClose,
}: {
  uuid: number;
  refetch: any;
  open: boolean;
  onClose: any;
}) {
  const { mutate } = useUpdate({});
  const { formProps, form } = useForm({
    resource: 'price_list',
    id: uuid,
  });

  const updateRecord = async (value: any) => {
    mutate(
      {
        resource: 'price_list/save',
        values: {
          ...value,
        },
        id: uuid,
        successNotification: (data, values, resource) => {
          return {
            message: 'Запись обновлена',
            type: 'success',
          };
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
    onClose();
  };

  return (
    <Modal open={open} footer={[]} onCancel={onClose} title="Редактор">
      <Form
        form={form}
        {...formProps}
        onFinish={updateRecord}
        layout="vertical"
      >
        <Form.Item label="Title în Ro" name="labelEn">
          <Input />
        </Form.Item>
        <Form.Item label="Название на русском" name="labelRu">
          <Input />
        </Form.Item>
        <Form.Item label="Номер в списке" name="position">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Цена" name="mdl">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button style={{ float: 'right' }} type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
