import React, { SyntheticEvent, useState } from 'react';
import { useLogin, useNotification } from '@pankod/refine-core';
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Modal,
  Alert,
  TabsProps,
  Tabs,
  FormInstance,
  Space,
  Icons,
} from '@pankod/refine-antd';
import InputMask from 'react-input-mask';
import { InputProps, InputRef, Progress } from 'antd';

import './styles.css';
import { AxiosError, AxiosResponse } from 'axios';
import './styles.css';
import { axiosInstance } from '../../App';
const { Text, Title } = Typography;
const { PhoneOutlined, NumberOutlined } = Icons;
export interface ILoginForm {
  email?: string;
  password?: string;
  remember?: boolean;
  phone?: string;
  code?: string;
}
export interface IEmailForm {
  email: string;
}

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

function PasswordLoginForm(props: {
  form: FormInstance<ILoginForm>;
  onFinish: (values: any) => void;
  onClick: () => void;
}) {
  return (
    <Form<ILoginForm>
      layout="vertical"
      form={props.form}
      onFinish={props.onFinish}
      requiredMark={false}
      initialValues={{
        remember: false,
      }}
    >
      <Form.Item
        name="email"
        label="Электронная почта"
        rules={[{ required: true }]}
      >
        <Input size="large" placeholder="your@email.ru" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Пароль"
        rules={[{ required: true }]}
        style={{ marginBottom: '12px' }}
      >
        <Input.Password />
      </Form.Item>
      <Button type="primary" size="large" htmlType="submit" block>
        Войти
      </Button>
    </Form>
  );
}

export const Login: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const { open: notify } = useNotification();
  const [form] = Form.useForm<ILoginForm>();
  // const { mutate: login } = useLogin<ILoginForm>();
  const [current, setCurrent] = useState<'phone' | 'code'>('phone');
  const [phone, setGsmNumber] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { open, close } = useNotification();
  const { mutate: login, isLoading } = useLogin<ILoginForm>();

  const onGsmFormSubmit = async (values: Pick<ILoginForm, 'phone'>) => {
    setLoading(true);
    if (values?.phone) {
      setGsmNumber(values.phone);
    }
    let code;
    try {
      code = await axiosInstance.get(
        process.env.REACT_APP_BACKEND_URL +
          '/api/v1/auth/code?phone=' +
          values.phone,
      );
      setCurrent('code');
      if (open) {
        open({
          description: 'SMS с кодом отправлено',
          message:
            process.env.REACT_APP_PROD === '1'
              ? 'Код отправлен на указанный номер мобильного телефона'
              : 'Тестовый режим. Код: ' + code.data,
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
      }
    } catch (e) {
      setLoading(false);
      if (open) {
        open({
          description: 'Пользователь не найден',
          message: 'Убедитесь что телефон введен правильно',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  const onCodeResend = async (e: SyntheticEvent) => {
    e.preventDefault();
    await onGsmFormSubmit({ phone });
  };
  const onCodeFormSubmit = async (values: Pick<ILoginForm, 'code'>) => {
    try {
      const result = await login({
        email: phone,
        password: values.code,
        remember: true,
      });
    } catch (e) {
      if (open) {
        open({
          description: 'Неправильный код',
          message: 'Убедитесь что код введен правильно',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  const renderGSMForm = () => (
    <>
      <Form layout="vertical" requiredMark={false} onFinish={onGsmFormSubmit}>
        <Form.Item
          label="Номер мобильного телефона"
          name="phone"
          hasFeedback
          // validateTrigger='onSubmit'
          rules={[
            {
              validator: (_, value) => {
                // if (value.indexOf('8') === 3) {
                //   return Promise.reject(
                //     new Error('Мобильный номер не может начинаться с 8')
                //   );
                // } else {
                //   return Promise.resolve();
                // }
                if (value[3] === '9') {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error('Мобильный номер РФ должен начинаться с 9'),
                  );
                }
              },
            },
            {
              validator: (_, value) => {
                if (
                  ((+value[3] >= 1 && +value[3] <= 9) || value[3] === '0') &&
                  ((+value[4] >= 1 && +value[4] <= 9) || value[4] === '0') &&
                  ((+value[5] >= 1 && +value[5] <= 9) || value[5] === '0') &&
                  ((+value[7] >= 1 && +value[7] <= 9) || value[7] === '0') &&
                  ((+value[8] >= 1 && +value[8] <= 9) || value[8] === '0') &&
                  ((+value[9] >= 1 && +value[9] <= 9) || value[9] === '0') &&
                  ((+value[11] >= 1 && +value[11] <= 9) || value[11] === '0') &&
                  ((+value[12] >= 1 && +value[12] <= 9) || value[12] === '0') &&
                  ((+value[14] >= 1 && +value[14] <= 9) || value[14] === '0') &&
                  ((+value[15] >= 1 && +value[15] <= 9) || value[15] === '0')
                ) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error('Пожалуйста, введите номер мобильного'),
                  );
                }
              },
            },
          ]}
        >
          {/*<Input />*/}
          <InputMask
            placeholder={'+7(___)-___-__-__'}
            mask="+7(999)999-99-99"
            // maskChar=' '
          >
            {(
              inputProps: JSX.IntrinsicAttributes &
                InputProps &
                React.RefAttributes<InputRef>,
            ) => <Input {...inputProps} />}
          </InputMask>
        </Form.Item>
        {/* <Form.Item
          name="phone"
          label="Телефон"
          rules={[
            {
              required: true,
              message: 'Введите телефон',
            },
          ]}
        >
          <Input
            prefix={<PhoneOutlined style={{ color: '#00000040' }} />}
            maxLength={18}
            placeholder="+7(___)___-____"
          />
        </Form.Item> */}
        <Form.Item noStyle>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
            block
          >
            Отправить код
          </Button>
        </Form.Item>
      </Form>
    </>
  );

  const renderCodeForm = () => (
    <Form layout="vertical" requiredMark={false} onFinish={onCodeFormSubmit}>
      <Form.Item
        name="code"
        label="Код"
        rules={[
          {
            required: true,
            message: 'Введите код',
          },
        ]}
      >
        <Input
          type="password"
          maxLength={4}
          prefix={<NumberOutlined style={{ color: '#00000040' }} />}
        />
      </Form.Item>
      <Form.Item noStyle>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          block
          loading={isLoading}
        >
          Войти
        </Button>
      </Form.Item>
      {/*<a href="#" onClick={onCodeResend}>*/}
      {/*  Выслать код повторно*/}
      {/*</a>*/}
    </Form>
  );
  const CardTitle = (
    <Title level={3} className="title">
      Вход в кабинет
    </Title>
  );
  const onSendRecoverButton = ({ email }: IEmailForm) => {
    if (notify) {
      setSendDisabled(true);
      axiosInstance
        .post(process.env.REACT_APP_BACKEND_URL + '/api/v1/auth/send', {
          email,
        })
        .then((response: AxiosResponse) => {
          notify({
            description: 'Сообщение отправлено',
            message:
              'В случае, если этот email зарегистрирован на платформе, на него выслано сообщение. Проверьте, пожалуйста, Ваш почтовый ящик.',
            type: 'success',
            key: '2',
            undoableTimeout: 20000,
          });
        })
        .catch((error) => {
          if (error.message.indexOf('400') !== -1) {
            notify({
              description: 'Неправильный email',
              message: 'Убедитесь что email введен правильно',
              type: 'error',
              key: '2',
              undoableTimeout: 20000,
            });
          }
        });
    }
  };
  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          background:
            'radial-gradient(50% 50% at 50% 50%, rgb(47 47 47) 0%, rgb(0 0 0) 100%) 0% 0% / cover',
          backgroundSize: 'cover',
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <a href={'/'}>
                <img src="./logos.png" alt="Envy Logo" width="250" />
              </a>
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              {params.recovered && (
                <>
                  <Alert
                    showIcon
                    description={
                      'Вы успешно восстановили пароль. Пожалуйста, войдите в систему с новым паролем.'
                    }
                    type="info"
                  />
                  <br />
                </>
              )}
              <PasswordLoginForm
                form={form}
                onFinish={(values) => {
                  login(values);
                }}
                onClick={showModal}
              />
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
