import React, { useState } from 'react';
import {
  useGetIdentity,
  useLogout,
  useNotification,
  useTitle,
} from '@pankod/refine-core';
import {
  AntdLayout,
  Button,
  Popconfirm,
  Space,
  Spin,
  Title,
  Typography,
  Image,
  PageHeader,
} from '@pankod/refine-antd';
import {
  FieldTimeOutlined,
  UserOutlined,
  SwapOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { Avatar, Badge } from 'antd';
import { Role } from '../../../enums/role';
import { axiosInstance } from '../../../App';
import { UserStatus } from '../../../enums/user.status';
import { DocumentType } from '../../../enums/document.type';
import { IPicture } from '../../../interfaces';
import { useNavigate } from '@pankod/refine-react-router-v6';
const { Text } = Typography;
export const Header: React.FC = () => {
  const navigate = useNavigate();
  const { data: me, refetch: refetchMe, isError } = useGetIdentity();
  let avatar;
  if (me) {
    avatar = me.pictures?.find((p: IPicture) => p.type === DocumentType.AVATAR);
  }
  const { mutate: logout } = useLogout();
  if (isError) {
    logout();
  }
  const { open: notify } = useNotification();
  const [isSwitchDisabled, setSwitchDisabled] = useState(false);
  const confirmSwitch = async (
    e: React.MouseEvent<HTMLElement> | undefined,
  ) => {
    if (notify) {
      setSwitchDisabled(true);
      let result;
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/users/switch',
        );
        notify({
          description: 'Успешно',
          message: 'Режим переключен',
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
        // refetchMe();
        // setSwitchDisabled(false);
        window.location.replace(process.env.REACT_APP_BACKEND_URL + '/offers');
      } catch (e) {
        setSwitchDisabled(false);
        notify({
          description: 'Ошибка',
          message:
            'Неизвестная ошибка, но мы уже знаем о ней и работаем над ее устранением.',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  const cancel = (e: React.MouseEvent<HTMLElement> | undefined) => {
    // message.error('Click on No');
  };
  return me ? (
    <AntdLayout.Header
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0px 24px',
        height: '64px',
        backgroundColor: '#001528',
        fontSize: '12px',
      }}
    >
      <div className="my-lk-name" style={{ width: '120px' }}>
        {me.role === Role.DIRECTOR && <p>{'Личный кабинет Директора'}</p>}
      </div>
      {/* <DrawerBlock
          me={me}
          refetchMe={refetchMe}
          isVisible={isDrawerVisible}
          setVisible={setDrawerVisible}
        /> */}
      <div
        style={{
          margin: '0 auto',
          height: '100%',
        }}
      >
        {/* <PageHeader style={{ height: '50px', padding: 0 }}> */}
        <a href="https://envy.md">
          <img alt="Envy" src="/logos.png" height={'50px'} />
        </a>
        {/* </PageHeader> */}
      </div>
    </AntdLayout.Header>
  ) : (
    <Spin style={{ padding: 20 }} />
  );
};
