import React, { useContext, useEffect, useState } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNavigation,
  useNotification,
} from '@pankod/refine-core';

import { AntDesignOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Divider,
  Edit,
  Form,
  Input,
  ListButton,
  RcFile,
  Select,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  useFileUploadState,
  useForm,
  DatePicker,
  Row,
  Col,
  Card,
  Avatar,
  Modal,
  Space,
} from '@pankod/refine-antd';
import InputMask from 'react-input-mask';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { IPicture, IReview, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { Role } from '../../enums/role';
import { FormRule, InputProps, InputRef } from 'antd';
import { axiosInstance } from '../../App';
import { UserType } from '../../enums/user.type';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import { DocumentType } from '../../enums/document.type';
import { NbkiRating } from '../../enums/nbki.rating';
import { normalizeFile } from '../../utility/normalize';
import dadataAddress from 'utility/dadata/address';
import { uuidV4 } from 'utility/uuidv4';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { UserContext } from 'UserProvider';

dayjs.extend(customParseFormat);
const { Text } = Typography;
const { TextArea } = Input;
const files: string[] = [];

export const ReviewEdit: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { open: notify } = useNotification();
  const { onChange: onChangeAux } = useFileUploadState();
  const { onChange: onChangeNbki } = useFileUploadState();
  const { list } = useNavigation();
  const [userType, setUserType] = useState<UserType>();
  const [role, setRole] = useState<Role>();

  const { onChange } = useFileUploadState();

  let publicFileList: UploadFile<any>[] | undefined;
  let record: any;

  const removeFile = async (file: any) => {
    let result;
    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/media/remove',
          { file },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  const {
    formProps: props,
    saveButtonProps: saveProps,
    queryResult,
    form,
  } = useForm<IReview>({
    onMutationSuccess: () => {
      return true;
    },
    successNotification: {
      description: 'Успешно',
      message: 'Пользователь обновлен',
      type: 'success',
    },
  });

  let formValues;

  let formData: IReview, dirInitValues, avatar, isReady;
  if (queryResult?.isSuccess) {
    formData = queryResult.data.data;
    avatar = formData?.pictures?.find((p) => p.type === DocumentType.AVATAR);
    isReady = true;
    dirInitValues = {
      nameTitle: formData.nameTitle,
      rating: formData.rating,
      text: formData.text,
    };
  }

  const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name ||
        (file.url ? file.url.substring(file.url.lastIndexOf('/') + 1) : ''),
    );
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Загрузить фото</div>
    </button>
  );

  useEffect(() => {
    if (!queryResult?.data) return;
    if (queryResult?.data && queryResult?.data.data.pictures[0]) {
      setFileList([
        {
          uid: queryResult?.data.data.pictures[0].uuid,
          name: queryResult?.data.data.pictures[0].origName,
          status: 'done',
          url: `${process.env.REACT_APP_BACKEND_URL}${queryResult?.data.data.pictures[0].path}`,
        },
      ]);
    }
  }, [queryResult?.data]);

  return (
    <>
      {me?.uuid !== record?.uuid && (
        <Edit
          headerProps={{
            subTitle: '',
            breadcrumb: <></>,
            extra: <ListButton />,
          }}
          title="Править отзыв"
          saveButtonProps={{ ...saveProps, children: 'Сохранить' }}
        >
          <Card>
            <Form
              {...props}
              form={form}
              layout="vertical"
              initialValues={dirInitValues}
            >
              <Row gutter={[12, 12]}>
                <Col xs={24} md={17}>
                  <Form.Item
                    label="ФИО человека"
                    name="nameTitle"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={7}>
                  <Form.Item
                    label="Рейтинг"
                    name="rating"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input type="number" max={10} min={1} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Текст"
                name="text"
                hasFeedback
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Form>
            <Upload
              action={`${apiUrl}/media/upload`}
              listType="picture-card"
              fileList={fileList}
              headers={{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              }}
              onPreview={handlePreview}
              onChange={handleChange}
              style={{ width: 100 }}
              data={(e) => {
                const fileUuid = uuidV4();
                console.log(e);

                return {
                  fileUuid,
                  type: DocumentType.REVIEW,
                  origName: e.name,
                  review: formData.uuid,
                };
              }}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Card>
        </Edit>
      )}
    </>
  );
};
