export const dateStandartFormat = (date: string | Date): string => {
  const formatDate = new Date(date);
  const day = String(formatDate.getDate()).padStart(2, '0');
  const month = String(formatDate.getMonth() + 1).padStart(2, '0');
  const year = String(formatDate.getFullYear());
  const hours = String(formatDate.getHours()).padStart(2, '0');
  const minutes = String(formatDate.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};
