import React, { useState, useContext } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNotification,
} from '@pankod/refine-core';
import {
  Alert,
  AutoComplete,
  Create,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  Upload,
  UploadFile,
  useFileUploadState,
  useForm,
  Row,
  Col,
  Button,
} from '@pankod/refine-antd';
import { ArrowDownOutlined } from '@ant-design/icons';

import 'react-mde/lib/styles/css/react-mde-all.css';
import { IPicture, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { UserType } from '../../enums/user.type';
import { axiosInstance } from '../../App';
import moment from 'moment';
import { FormRule, InputProps, InputRef } from 'antd';
import { Role } from '../../enums/role';
import InputMask from 'react-input-mask';
import { normalizeFile } from '../../utility/normalize';
import { DocumentType } from '../../enums/document.type';
import { dateStandartFormat } from 'utility/dateStandartFormat';
import dadataAddress from 'utility/dadata/address';
import { UserContext } from 'UserProvider';
import { uuidV4 } from 'utility/uuidv4';
const { TextArea } = Input;
const { Text } = Typography;
export const NewsCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<IUser>({
    successNotification: {
      description: 'Новость создана',
      message: 'Просматривайте список новостей во вкладке новости',
      type: 'success',
    },
    errorNotification: {
      description: 'Новость не создана',
      message: 'Произошла непредвиденная ошибка',
      type: 'error',
    },
  });

  // const { data: me, refetch } = useGetIdentity<IUser>();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { open: notify } = useNotification();
  const { onChange } = useFileUploadState();
  const apiUrl = useApiUrl();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );

  const removeFile = async (file: any) => {
    let result;
    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/media/remove',
          { file },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  let publicFileList: UploadFile<any>[] | undefined;

  return (
    <Create
      headerProps={{
        subTitle: '',
      }}
      title="Создать новость"
      saveButtonProps={{ ...saveButtonProps, children: 'Сохранить' }}
    >
      <Form {...formProps} layout="vertical">
        <Upload
          action={`${apiUrl}/media/upload`}
          headers={{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }}
          listType="picture-card"
          fileList={publicFileList}
          onChange={(info) => {
            if (info.file.status === 'done') {
              console.log('Успешная загрузка', info.file.response);
              form.setFieldValue('picture', info.file.response.id);
            }
          }}
          maxCount={1}
          multiple={false}
          onRemove={removeFile}
          data={() => {
            const fileUuid = uuidV4();
            return { fileUuid, type: DocumentType.NEWS };
          }}
        >
          Загрузить
        </Upload>
        <Form.Item name="picture" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Название на русском"
          name="labelRu"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Titlu în romana"
          name="labelEn"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Текст рус"
          name="textRu"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item
          label="Text ro"
          name="textEn"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Create>
  );
};
