import {
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
} from '@pankod/refine-core';
import {
  Button,
  CreateButton,
  DateField,
  DeleteButton,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Image,
  Input,
  List,
  NumberField,
  Select,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  Tooltip,
  useTable,
} from '@pankod/refine-antd';

import { SolutionOutlined, FileAddOutlined } from '@ant-design/icons';
import { IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext } from 'react';
import { Role } from '../../enums/role';
import { ModalAgentList } from 'components/users/modalAgentList';
import { UserContext } from 'UserProvider';

export const NewsList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;

  // const { data: me } = useGetIdentity<IUser>();
  const initialFilter: CrudFilters = [];

  const { tableProps, sorter } = useTable<IUser>({
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    initialFilter,
  });
  return (
    <List
      headerProps={{
        extra: (
          <CreateButton
            style={{ marginTop: 10 }}
            type={'dashed'}
            children="Создать новость"
          />
        ),
      }}
    >
      <Table {...tableProps} rowKey="uuid">
        <Table.Column
          dataIndex="uuid"
          key="uuid"
          title="№"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder('phone', sorter)}
          sorter
        />

        <Table.Column
          dataIndex="pictures"
          key="pictures"
          title="Изображение"
          render={(value) => {
            if (!value) {
              return <></>;
            }
            return (
              <Image
                width={80}
                alt="envy_pic"
                src={`${process.env.REACT_APP_BACKEND_URL}${value.path}`}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="labelRu"
          key="labelRu"
          title="Заголовок Рус"
          render={(value) => (
            <TextField style={{ whiteSpace: 'nowrap' }} value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder('email', sorter)}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="labelEn"
          key="labelEn"
          title="Titlu Ro"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder('lastName', sorter)}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="textEn"
          key="textEn"
          title="Text Ro"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder('firstName', sorter)}
          sorter
        />

        <Table.Column
          dataIndex="textRu"
          key="textRu"
          title="Текст Рус"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder('firstName', sorter)}
          sorter
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="Создан"
          render={(value) => (
            <DateField value={value} format={'DD.MM.YYYY, HH:mm'} />
          )}
          defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
          sorter
        />
        <Table.Column
          key="action"
          title="Функции"
          render={(_, value: any) => (
            <Space size={12}>
              <DeleteButton recordItemId={value.uuid} hideText size="small" />
              <EditButton recordItemId={value.uuid} hideText size="small" />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
