import {
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNotification,
  useShow,
} from '@pankod/refine-core';
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  TagField,
  ShowButton,
  NumberField,
  Spin,
  Button,
  EditButton,
  DeleteButton,
  Image,
} from '@pankod/refine-antd';
import { IUser, IAgreement, IPayoutRequest, IMessage } from 'interfaces';
import React, { useContext, useState } from 'react';
import { Role } from '../../enums/role';
import { MessageStatus } from '../../enums/message.status';
import { UserContext } from 'UserProvider';
import { axiosInstance } from 'App';
import { ModalShowMsg } from 'components/messages/modalShowMsg';

export const ArticleList: React.FC<IResourceComponentsProps> = () => {
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const filters: CrudFilters = [];

  const { open: notify } = useNotification();

  const { tableProps, sorter, tableQueryResult } = useTable<IMessage>({
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    initialFilter: filters,
  });
  const { data, isLoading, refetch } = tableQueryResult;

  return (
    <>
      <List>
        <Table {...tableProps} rowKey="uuid">
          <Table.Column
            width={32}
            key="action"
            render={(value, record: any) => (
              <Space size={12}>
                <EditButton size="small" recordItemId={record.uuid} hideText />
              </Space>
            )}
          />
          <Table.Column
            dataIndex="nameTitle"
            key="nameTitle"
            title="Заголовок"
            render={(value, record: any) => (
              <TextField
                strong={record.status === MessageStatus.UNREAD}
                value={value}
              />
            )}
            defaultSortOrder={getDefaultSortOrder('topic', sorter)}
            sorter
          />
          <Table.Column
            dataIndex="text"
            key="text"
            title="Текст"
            render={(value, record: any) => (
              <TextField
                strong={record.status === MessageStatus.UNREAD}
                value={value}
              />
            )}
            defaultSortOrder={getDefaultSortOrder('text', sorter)}
            sorter
          />
          <Table.Column
            key="picture"
            title="Изображение"
            render={(value, record: any) =>
              record?.pictures.length >= 1 ? (
                <>
                  <Image
                    alt="Фото категории"
                    src={`${process.env.REACT_APP_BACKEND_URL}${record?.pictures[0].path}`}
                    width={96}
                  />
                </>
              ) : (
                <>Нет фото</>
              )
            }
          />
        </Table>
      </List>
    </>
  );
};
