import React, { useContext, useEffect, useState } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNavigation,
  useNotification,
} from '@pankod/refine-core';

import {
  AntDesignOutlined,
  UserOutlined,
  SolutionOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Divider,
  Edit,
  Form,
  Input,
  ListButton,
  RcFile,
  Select,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  useFileUploadState,
  useForm,
  DatePicker,
  Row,
  Col,
  Card,
  Avatar,
  Space,
} from '@pankod/refine-antd';
import InputMask from 'react-input-mask';
import 'react-mde/lib/styles/css/react-mde-all.css';

import { IPicture, IPrice, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { Role } from '../../enums/role';
import { FormRule, InputProps, InputRef } from 'antd';
import { axiosInstance } from '../../App';
import { UserType } from '../../enums/user.type';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import { DocumentType } from '../../enums/document.type';
import { NbkiRating } from '../../enums/nbki.rating';
import { normalizeFile } from '../../utility/normalize';
import dadataAddress from 'utility/dadata/address';
import { uuidV4 } from 'utility/uuidv4';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { UserContext } from 'UserProvider';
import { PriceType } from 'enums/price.type';
import { PriceLists } from './price_list';

dayjs.extend(customParseFormat);
const { Text } = Typography;
const files: string[] = [];

export const PriceEdit: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { open: notify } = useNotification();
  const { onChange: onChangeAux } = useFileUploadState();
  const { onChange: onChangeNbki } = useFileUploadState();
  const { list } = useNavigation();
  const [userType, setUserType] = useState<UserType>();
  const [role, setRole] = useState<Role>();

  let record: any;

  const {
    formProps: props,
    saveButtonProps: saveProps,
    queryResult,
    form,
  } = useForm<IPrice>({
    onMutationSuccess: () => {
      return true;
    },
    successNotification: {
      description: 'Успешно',
      message: 'Список обновлен',
      type: 'success',
    },
  });

  let formValues;

  let formData, dirInitValues, avatar, isReady;
  if (queryResult?.isSuccess) {
    formData = queryResult.data.data;

    isReady = true;
    dirInitValues = {
      labelRu: formData.labelRu,
      labelEn: formData.labelEn,
      type: formData.type,
      position: formData.position,
    };
  }

  return (
    <>
      {formData && me?.uuid !== record?.uuid && (
        <Card size="small">
          <Edit
            headerProps={{
              subTitle: '',
              breadcrumb: <></>,
              extra: <ListButton />,
            }}
            title="Править группу"
            saveButtonProps={{ ...saveProps, children: 'Сохранить' }}
          >
            <Form
              {...props}
              form={form}
              layout="vertical"
              initialValues={dirInitValues}
            >
              <Row gutter={[12, 12]}>
                <Col sm={24} md={7}>
                  <Form.Item
                    label="Название на русском"
                    name="labelRu"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={24} md={7}>
                  <Form.Item
                    label="Titlu in românǎ"
                    name="labelEn"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={24} md={4}>
                  <Form.Item
                    label="Позиция в списке"
                    name="position"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col sm={24} md={6}>
                  <Form.Item
                    label="Принадлежность к типу"
                    name="type"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {
                          label: PriceType.SERVICES,
                          value: PriceType.SERVICES,
                        },
                        {
                          label: PriceType.ITEMS,
                          value: PriceType.ITEMS,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Edit>
        </Card>
      )}

      <Card title="Содержание группы" size="small" style={{ marginTop: 16 }}>
        <PriceLists uuid={props.initialValues?.uuid} />
      </Card>
    </>
  );
};
