import React, { useState, useContext } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNotification,
} from '@pankod/refine-core';
import {
  Alert,
  AutoComplete,
  Create,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  Upload,
  UploadFile,
  useFileUploadState,
  useForm,
  Row,
  Col,
  Button,
} from '@pankod/refine-antd';
import { ArrowDownOutlined } from '@ant-design/icons';

import 'react-mde/lib/styles/css/react-mde-all.css';
import { IPicture, IPrice, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { UserType } from '../../enums/user.type';
import { axiosInstance } from '../../App';
import moment from 'moment';
import { FormRule, InputProps, InputRef } from 'antd';
import { Role } from '../../enums/role';
import InputMask from 'react-input-mask';
import { normalizeFile } from '../../utility/normalize';
import { DocumentType } from '../../enums/document.type';
import { dateStandartFormat } from 'utility/dateStandartFormat';
import dadataAddress from 'utility/dadata/address';
import { UserContext } from 'UserProvider';
import { uuidV4 } from 'utility/uuidv4';
import { PriceType } from 'enums/price.type';
const { Text } = Typography;
export const PriceCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<IPrice>({
    successNotification: {
      description: 'Раздел создан',
      message: 'Просматривайте разделы во вкладке Прайс Лист',
      type: 'success',
    },
    errorNotification: {
      description: 'Раздел не создан',
      message: 'Возникла непредвиденная ошибка',
      type: 'error',
    },
  });
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { open: notify } = useNotification();
  const apiUrl = useApiUrl();
  const [userType, setUserType] = useState<UserType>();
  const [role, setRole] = useState<Role>();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const handleSearch = async (value: string) => {
    let res: { value: string; label: string }[] = [];
    const dadataSugs = await dadataAddress(value);
    res = dadataSugs.map((s: any) => {
      return {
        value: s.unrestricted_value,
        label: s.unrestricted_value,
        disabled: !s.data.house,
      };
    });
    setOptions(res);
  };
  const [cantEdit, setCantEdit] = useState<boolean>(false);
  const [hideIp, setHideIp] = useState<boolean>(true);
  //

  return (
    <Create
      headerProps={{
        subTitle: '',
      }}
      title="Создать раздел"
      saveButtonProps={{ ...saveButtonProps, children: 'Сохранить' }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Название на русском"
          name="labelRu"
          hasFeedback
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Titlu in românǎ"
          name="labelEn"
          hasFeedback
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Позиция в списке"
          name="position"
          hasFeedback
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Принадлежность к типу"
          name="type"
          hasFeedback
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            options={[
              {
                label: PriceType.SERVICES,
                value: PriceType.SERVICES,
              },
              {
                label: PriceType.ITEMS,
                value: PriceType.ITEMS,
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
