import {
  CrudFilters,
  IResourceComponentsProps,
  useDelete,
  useGetIdentity,
  useList,
  useMany,
  useMutation,
  useNavigation,
  useQuery,
  useUpdate,
} from '@pankod/refine-core';
import {
  Button,
  Col,
  CreateButton,
  DateField,
  DeleteButton,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  Modal,
  NumberField,
  Row,
  Select,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  Tooltip,
  useForm,
  useTable,
} from '@pankod/refine-antd';

import { SolutionOutlined, FileAddOutlined } from '@ant-design/icons';
import { IPriceList, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Role } from '../../enums/role';
import { ModalAgentList } from 'components/users/modalAgentList';
import { UserContext } from 'UserProvider';
import ListEdit from 'components/prices/listEdit';

export const PriceLists = ({ uuid }: { uuid: number }) => {
  console.log(uuid);
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;

  // const { data: me } = useGetIdentity<IUser>();
  const initialFilter: CrudFilters = [];

  const { tableProps, sorter, setFilters, tableQueryResult } =
    useTable<IPriceList>({
      resource: 'price_list',
      initialSorter: [
        {
          field: 'position',
          order: 'asc',
        },
      ],
      initialPageSize: 100,
      initialFilter,
      liveMode: 'auto',
    });
  const { refetch } = tableQueryResult;
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const { formProps, saveButtonProps, queryResult, form } = useForm<IPriceList>(
    {
      resource: 'price_list',
      action: 'create',
      onMutationSuccess: () => setShowAddModal(false),
      redirect: false,
      successNotification: {
        description: 'Элемент создан',
        message: 'Просматривайте разделы во вкладке Прайс Лист',
        type: 'success',
      },
      errorNotification: {
        description: 'Элемент не создан',
        message: 'Возникла непредвиденная ошибка',
        type: 'error',
      },
    },
  );
  useEffect(() => {
    const filters: CrudFilters = [
      { field: 'price', operator: 'eq', value: +uuid },
    ];
    setFilters(filters);
    form.setFieldValue('price', uuid);
  }, [uuid]);

  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [selecterUuid, setSelectedUuid] = useState<number | null>(null);
  return (
    <List
      headerProps={{
        extra: (
          <Button
            onClick={() => setShowAddModal(true)}
            type={'dashed'}
            children="Добавить"
          />
        ),
      }}
    >
      <Modal
        title="Добавление в категорию"
        open={showAddModal}
        onOk={saveButtonProps.onClick}
        onCancel={() => setShowAddModal(false)}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item
            label="Название на русском"
            name="labelRu"
            hasFeedback
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Title (Ro)"
            name="labelEn"
            hasFeedback
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Позиция в списке"
            name="position"
            hasFeedback
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Цена"
            name="mdl"
            hasFeedback
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="price"
            name="price"
            hidden
            hasFeedback
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {selecterUuid && (
        <ListEdit
          uuid={selecterUuid}
          refetch={refetch}
          open={openModalEdit}
          onClose={() => setOpenModalEdit(false)}
        />
      )}
      <Table {...tableProps} rowKey="uuid">
        <Table.Column
          dataIndex="position"
          key="position"
          title="Номер в списке(для сортировки)"
          render={(value) => <TextField value={value} />}
          sorter
        />
        <Table.Column
          dataIndex="labelEn"
          key="labelEn"
          title="Title(ro)"
          render={(value) => (
            <TextField style={{ whiteSpace: 'nowrap' }} value={value} />
          )}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="labelRu"
          key="labelRu"
          title="Заголовок(ру)"
          render={(value) => <TextField value={value} />}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="mdl"
          key="mdl"
          title="Цена"
          render={(value) => <TextField value={value} />}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="uuid"
          key="uuid"
          title=""
          render={(record) => (
            <Space size={12}>
              <EditButton
                size="small"
                onClick={() => {
                  setSelectedUuid(record);
                  setOpenModalEdit(true);
                }}
                hideText
              />
              <DeleteButton
                disabled={false}
                resourceNameOrRouteName="price_list"
                hideText
                size="small"
                recordItemId={record}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
