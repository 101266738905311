import React, { useState } from 'react';

import {
  useLogout,
  useTitle,
  CanAccess,
  ITreeMenu,
  useMenu,
  useRefineContext,
  useRouterContext,
  useGetIdentity,
} from '@pankod/refine-core';
import {
  AntdLayout,
  Menu,
  Grid,
  Icons,
  Popover,
  Button,
} from '@pankod/refine-antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { antLayoutSider, antLayoutSiderMobile } from './styles';
import Profile from '../header/profileIco';
import { TicketModal } from '../header/ticketModal';

const { UnorderedListOutlined, LogoutOutlined, QuestionCircleOutlined } = Icons;

const SiderComponent: React.FC = () => {
  console.log('Sider rendered');
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isTicketModalVisible, setTicketModalVisible] =
    useState<boolean>(false);
  const { mutate: logout } = useLogout();
  const { data: me } = useGetIdentity();
  const { Link } = useRouterContext();
  const { hasDashboard } = useRefineContext();
  const Title = useTitle();
  const { SubMenu } = Menu;
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item;

      if (children.length > 0) {
        return (
          <SubMenu
            key={route}
            icon={icon ?? <UnorderedListOutlined />}
            title={label}
            style={{
              color: 'white',
            }}
          >
            {renderTreeView(children, selectedKey)}
          </SubMenu>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);
      return (
        <CanAccess key={route} resource={name.toLowerCase()} action="list">
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? 'bold' : '300',
              fontSize: 14,
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link href={route} to={route}>
              {label}
            </Link>
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  return (
    <>
      <TicketModal
        me={me}
        isVisible={isTicketModalVisible}
        setVisible={setTicketModalVisible}
      />
      <AntdLayout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
        collapsedWidth={isMobile ? 0 : 80}
        breakpoint="lg"
        width={280}
      >
        <Profile isMobile={isMobile} colapsed={collapsed} me={me} />
        <Menu
          selectedKeys={[selectedKey]}
          defaultOpenKeys={defaultOpenKeys}
          // mode="inline"
          theme={'dark'}
          onClick={() => {
            if (!breakpoint.lg) {
              setCollapsed(true);
            }
          }}
        >
          {hasDashboard && (
            <Menu.Item
              key="dashboard"
              style={{
                fontWeight: selectedKey === '/' ? 'bold' : '300',
              }}
              icon={<Icons.DashboardOutlined />}
            >
              <Link href="/" to="/">
                Dashboard
              </Link>
              {!collapsed && selectedKey === '/' && (
                <div className="ant-menu-tree-arrow" />
              )}
            </Menu.Item>
          )}
          {renderTreeView(menuItems, selectedKey)}

          <Menu.Item
            key="logout"
            onClick={() => {
              logout();
            }}
            icon={<LogoutOutlined />}
          >
            Выйти
          </Menu.Item>
        </Menu>
      </AntdLayout.Sider>
    </>
  );
};
export const Sider = React.memo(SiderComponent);
