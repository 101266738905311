import React, { useState, useContext } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNotification,
} from '@pankod/refine-core';
import {
  Alert,
  AutoComplete,
  Create,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  Upload,
  UploadFile,
  useFileUploadState,
  useForm,
  Row,
  Col,
  Button,
} from '@pankod/refine-antd';
import { ArrowDownOutlined } from '@ant-design/icons';

import 'react-mde/lib/styles/css/react-mde-all.css';
import { IPicture, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { UserType } from '../../enums/user.type';
import { axiosInstance } from '../../App';
import moment from 'moment';
import { FormRule, InputProps, InputRef } from 'antd';
import { Role } from '../../enums/role';
import InputMask from 'react-input-mask';
import { normalizeFile } from '../../utility/normalize';
import { DocumentType } from '../../enums/document.type';
import { dateStandartFormat } from 'utility/dateStandartFormat';
import dadataAddress from 'utility/dadata/address';
import { UserContext } from 'UserProvider';
import { uuidV4 } from 'utility/uuidv4';
const { Text } = Typography;
export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<IUser>({
    successNotification: {
      description: 'Пользователь создан',
      message: 'Просматривайте пользователей в разделе Пользователи',
      type: 'success',
    },
    errorNotification: {
      description: 'Пользователь не создан',
      message: 'Email или телефон уже заняты',
      type: 'error',
    },
  });
  const [files, setFiles] = useState<string[]>([]);
  // const { data: me, refetch } = useGetIdentity<IUser>();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  console.log('users create.tsx', me?.uuid);
  const { open: notify } = useNotification();
  const { onChange } = useFileUploadState();
  const { onChange: onChange2 } = useFileUploadState();
  const { onChange: onChange3 } = useFileUploadState();
  const { onChange: onChange4 } = useFileUploadState();
  const { onChange: onChange5 } = useFileUploadState();
  const { onChange: onChange6 } = useFileUploadState();
  const apiUrl = useApiUrl();
  const [userType, setUserType] = useState<UserType>();
  const [role, setRole] = useState<Role>();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const handleSearch = async (value: string) => {
    let res: { value: string; label: string }[] = [];
    const dadataSugs = await dadataAddress(value);
    res = dadataSugs.map((s: any) => {
      return {
        value: s.unrestricted_value,
        label: s.unrestricted_value,
        disabled: !s.data.house,
      };
    });
    setOptions(res);
  };
  const [cantEdit, setCantEdit] = useState<boolean>(false);
  const [hideIp, setHideIp] = useState<boolean>(true);
  //
  const dadataBank = (event: any) => {
    if (event.target.value.length >= 9) {
      axiosInstance
        .get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/dadata/bank/' +
            event.target.value,
        )
        .then((res) => {
          form.setFieldsValue({
            bankName: res.data.suggestions[0].data.name.short,
            bankCorNumber: res.data.suggestions[0].data.correspondent_account,
          });
        });
    }
  };
  const datataOrg = (inn: string) => {
    function getFounders(arrFounders: any) {
      let foundersString = '';
      arrFounders.forEach((el: any, id: number) => {
        foundersString += `${el.fio.source} ${el.share.value}%`;
        if (id !== arrFounders.length - 1) {
          foundersString += ' ,';
        }
      });
      return foundersString;
    }
    if (inn.length === 10) {
      setUserType(UserType.UL);

      axiosInstance
        .get(process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/' + inn)
        .then((res) => {
          setHideIp(true);
          form.setFieldsValue({
            type: UserType.UL,
          });
          form.setFieldsValue({
            organisationInn: inn,
          });
          form.setFieldsValue({
            managerPosition: res.data.suggestions[0].data.managers[0].post,
          });
          form.setFieldsValue({
            orgFullName: res.data.suggestions[0].data.name.full_with_opf,
          });
          form.setFieldsValue({
            orgName: res.data.suggestions[0].data.name.short_with_opf,
          });
          form.setFieldsValue({
            revenue: +res.data.suggestions[0].data.finance.income,
          });
          form.setFieldsValue({
            income:
              +res.data.suggestions[0].data.finance.income -
              res.data.suggestions[0].data.finance.expense,
          });
          form.setFieldsValue({
            infoDebt: res.data.suggestions[0].data.finance.debt,
          });
          form.setFieldsValue({
            infoFounders: JSON.stringify(res.data.suggestions[0].data.founders),
          });
          form.setFieldsValue({
            regDate: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          form.setFieldsValue({
            registrDate: dateStandartFormat(
              new Date(res.data.suggestions[0].data.ogrn_date),
            ),
          });
          form.setFieldsValue({
            infoTax: res.data.suggestions[0].data.finance.tax_system,
          });
          form.setFieldsValue({
            inn: res.data.suggestions[0].data.managers[0].inn,
          });
          form.setFieldsValue({
            firstName: res.data.suggestions[0].data.managers[0].fio.name,
          });

          form.setFieldsValue({
            middleName: res.data.suggestions[0].data.managers[0].fio.patronymic,
          });
          form.setFieldsValue({
            lastName: res.data.suggestions[0].data.managers[0].fio.surname,
          });
          form.setFieldsValue({
            infoActivities: res.data.suggestions[0].data.okveds[0].name,
          });
          form.setFieldsValue({ orgOgrn: res.data.suggestions[0].data.ogrn });
          form.setFieldsValue({ kpp: res.data.suggestions[0].data.kpp });
          form.setFieldsValue({ orgName: res.data.suggestions[0].value });
          form.setFieldsValue({
            orgLocation: res.data.suggestions[0].data.address.value,
          });
          form.setFieldsValue({
            ipRegDate: moment(res.data.suggestions[0].data.ogrn_date).format(
              'DD.MM.YYYY',
            ),
          });
          form.setFieldsValue({
            capital: res.data.suggestions[0].data.capital.value,
          });
          form.setFieldsValue({
            infoStructure: res.data.suggestions[0].data.management.name,
          });

          form.setFieldsValue({
            infoPercent: getFounders(res.data.suggestions[0].data.founders),
          });

          setCantEdit(true);
        });
    }
  };
  const dadataPersonal = (inn: string) => {
    if (inn.length >= 12) {
      setUserType(UserType.IP);
      setHideIp(false);

      axiosInstance
        .get(process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/' + inn)
        .then((res) => {
          form.setFieldsValue({
            type: UserType.IP,
          });
          form.setFieldsValue({
            organisationInn: inn,
          });
          form.setFieldsValue({
            managerPosition: res.data.suggestions[0].data.opf.full,
          });
          form.setFieldsValue({
            orgFullName: res.data.suggestions[0].data.name.full_with_opf,
          });
          form.setFieldsValue({
            ipRegDate: moment(res.data.suggestions[0].data.ogrn_date).format(
              'DD.MM.YYYY',
            ),
          });

          form.setFieldsValue({ orgOgrn: res.data.suggestions[0].data.ogrn });
          form.setFieldsValue({ kpp: res.data.suggestions[0].data.kpp });
          form.setFieldsValue({ orgName: res.data.suggestions[0].value });
          form.setFieldsValue({
            orgLocation: res.data.suggestions[0].data.address.value,
          });
          form.setFieldsValue({
            firstName: res.data.suggestions[0].data.fio.name,
          });
          form.setFieldsValue({
            middleName: res.data.suggestions[0].data.fio.patronymic,
          });
          form.setFieldsValue({
            lastName: res.data.suggestions[0].data.fio.surname,
          });
          form.setFieldsValue({
            infoActivities: res.data.suggestions[0].data.okveds[0].name,
          });
          form.setFieldsValue({
            registrDate: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          form.setFieldsValue({
            ipRegDate: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          form.setFieldsValue({
            inn: inn,
          });
          form.setFieldsValue({
            infoStructure: res.data.suggestions[0].data.name.full,
          });
          form.setFieldsValue({
            infoPercent: res.data.suggestions[0].data.name.full,
          });
          console.log(res.data.suggestions[0].data.state.registration_date);
        });
    }
  };

  const checkDatata = (event: any) => {
    const inn = event.target.value;

    if (inn.length === 10) {
      datataOrg(inn);
    }

    if (inn.length >= 12) {
      dadataPersonal(inn);
    }
  };

  const removeFile = async (file: any) => {
    let result;
    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/media/remove',
          { file },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  let publicFileList: UploadFile<any>[] | undefined,
    publicFileList2: UploadFile<any>[] | undefined,
    publicFileList3: UploadFile<any>[] | undefined,
    publicFileList4: UploadFile<any>[] | undefined,
    publicFileList5: UploadFile<any>[] | undefined,
    publicFileList6: UploadFile<any>[] | undefined;
  const onType = (type: UserType) => {
    setUserType(type);
  };
  const onRole = (roles: Role) => {
    setRole(roles);
  };
  const innRule: FormRule = {
    type: 'string',
    min: 12,
    max: 12,
    message: 'ИНН физлица содержит 12 цифр',
  };
  const orgInnRule: FormRule = {
    type: 'string',
    min: 10,
    max: 12,
    message: 'ИНН юрлица содержит 10 цифр',
  };
  const orgOgrnRule: FormRule = {
    type: 'string',
    min: 13,
    max: 13,
    message: 'ОГРН юрлица содержит 13 цифр',
  };
  const ogrnipRule: FormRule = {
    type: 'string',
    min: 15,
    max: 15,
    message: 'ОГРНИП содержит 15 цифр',
  };

  const onInfoSubmit = async (values: IUser) => {
    let result;
    if (!me) return;

    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/users/saveNewUser',
          { phone: me.phone, ...values },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: 'Убедитесь что данные заполнены правильно',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  return (
    <Create
      headerProps={{
        subTitle: '',
      }}
      title="Создать пользователя"
      saveButtonProps={{ ...saveButtonProps, children: 'Сохранить' }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Имя"
          name="firstName"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Фамилия"
          name="lastName"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Электронная почта"
          name="email"
          hasFeedback
          rules={[
            {
              required: true,
            },
            {
              type: 'email',
              message: 'Пожалуйста, введите Ваш email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* пароль */}
        <Form.Item
          name="password"
          label="Придумайте пароль"
          rules={[
            {
              required: true,
              min: 6,
              max: 20,
              message: 'Введите пароль (от 6 до 20 символов)',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Повторите ввод пароля"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Пожалуйста, повторите ввод пароля',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Введенные пароли не совпадают'),
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Тип участника платформы"
          name="role"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: Role.ADMIN,
                value: Role.ADMIN,
              },
              {
                label: Role.DIRECTOR,
                value: Role.DIRECTOR,
              },
              {
                label: Role.MANAGER,
                value: Role.MANAGER,
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
