import {
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
} from '@pankod/refine-core';
import {
  Button,
  CreateButton,
  DateField,
  DeleteButton,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Input,
  List,
  NumberField,
  Select,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  Tooltip,
  useTable,
} from '@pankod/refine-antd';

import { SolutionOutlined, FileAddOutlined } from '@ant-design/icons';
import { IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useState } from 'react';
import { Role } from '../../enums/role';
import { ModalAgentList } from 'components/users/modalAgentList';
import { UserContext } from 'UserProvider';

export const PriceList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;

  // const { data: me } = useGetIdentity<IUser>();
  const initialFilter: CrudFilters = [];

  const { tableProps, sorter } = useTable<IUser>({
    initialSorter: [
      {
        field: 'position',
        order: 'asc',
      },
    ],
    initialPageSize: 100,
    initialFilter,
  });
  return (
    <List
      headerProps={{
        extra: (
          <CreateButton
            style={{ marginTop: 10 }}
            type={'dashed'}
            children="Добавить категорию"
          />
        ),
      }}
    >
      {open && (
        <ModalAgentList
          uuid={selectedUuid}
          open={open}
          cancel={() => setOpen(false)}
        />
      )}
      <Table {...tableProps} rowKey="uuid">
        <Table.Column
          dataIndex="position"
          key="position"
          title="Номер в списке(для сортировки)"
          render={(value) => <TextField value={value} />}
          sorter
        />
        <Table.Column
          dataIndex="labelEn"
          key="labelEn"
          title="Title in românǎ"
          render={(value) => (
            <TextField style={{ whiteSpace: 'nowrap' }} value={value} />
          )}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="labelRu"
          key="labelRu"
          title="Заголовок на русском"
          render={(value) => <TextField value={value} />}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="type"
          key="type"
          title="Тип"
          render={(value) => <TextField value={value} />}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="uuid"
          key="uuid"
          title="Функции"
          render={(record) => (
            <Space size={12}>
              <EditButton hideText size="small" recordItemId={record} />
              <DeleteButton hideText size="small" recordItemId={record} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
