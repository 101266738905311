import {
  CrudFilters,
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useInfiniteList,
  useList,
  useNavigation,
  useDelete,
  useNotification,
} from '@pankod/refine-core';
import {
  Button,
  Col,
  CreateButton,
  DateField,
  DeleteButton,
  Divider,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Image,
  Input,
  List,
  NumberField,
  Row,
  Select,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  Tooltip,
  Upload,
  UploadFile,
  useTable,
} from '@pankod/refine-antd';
import { PlusOutlined } from '@ant-design/icons';
import { SolutionOutlined, FileAddOutlined } from '@ant-design/icons';
import { IPicture, IUser, IWork } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Role } from '../../enums/role';
import { ModalAgentList } from 'components/users/modalAgentList';
import { UserContext } from 'UserProvider';
import { uuidV4 } from 'utility/uuidv4';
import { DocumentType } from 'enums/document.type';
import { axiosInstance } from 'App';

export const WorkList: React.FC<IResourceComponentsProps> = () => {
  const userContext = useContext(UserContext);
  const { open: notify } = useNotification();
  let publicFileList: UploadFile<any>[] | undefined;
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const me = userContext ? userContext.user : null;
  const apiUrl = useApiUrl();
  const { data, isError, isLoading, refetch } = useList<IWork>({
    resource: 'work',
    liveMode: 'auto',
    config: {
      pagination: { pageSize: 999 },
    },
  });

  useEffect(() => {
    if (!data?.data) return;
    const images: any[] = data?.data.map((el: IWork) => {
      return {
        uid: el.pictures.id,
        name: el.pictures.origName,
        url: `${process.env.REACT_APP_BACKEND_URL}${el.pictures.path}`,
      };
    });
    setFileList(images);
  }, [data]);

  useEffect(() => {
    if (fileList.length > 0) {
      refetch();
    }
  }, [fileList]);

  const handleUploadChange = (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1); // Ограничиваем список файлов до одного файла
    setFileList(fileList);
  };

  if (isLoading) {
    return <p>Загрузка</p>;
  }
  if (isError) {
    return <p>Что-то пошло не так </p>;
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Загрузить</div>
    </button>
  );

  const handleDelete = async (image: any) => {
    if (notify) {
      try {
        await axiosInstance.delete(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/work/${image.uid}`,
        );
        refetch();
        notify({
          description: 'Успешно!',
          message: 'Фотография удалена!',
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
      } catch (error) {
        notify({
          description: 'Неудача!',
          message: 'Фотография не удалена!',
          type: 'error',
          key: '1',
          undoableTimeout: 20000,
        });
      }
    }
  };

  return (
    <List>
      <Upload
        action={`${apiUrl}/media/upload`}
        headers={{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }}
        listType="picture-card"
        onRemove={handleDelete}
        onChange={handleUploadChange}
        fileList={fileList}
        data={() => {
          const fileUuid = uuidV4();
          return { fileUuid, type: DocumentType.WORKS };
        }}
      >
        {uploadButton}
      </Upload>
    </List>
  );
};
<List>
  {/* <Table {...tableProps} rowKey="uuid">
      <Table.Column
        dataIndex="uuid"
        key="uuid"
        title="№ ЛС"
        render={(value) => <TextField value={value} />}
        defaultSortOrder={getDefaultSortOrder('phone', sorter)}
        sorter
      />

      <Table.Column
        dataIndex="email"
        key="email"
        title="Email"
        render={(value) => (
          <EmailField style={{ whiteSpace: 'nowrap' }} value={value} />
        )}
        defaultSortOrder={getDefaultSortOrder('email', sorter)}
        sorter
        filterDropdown={(props: FilterDropdownProps) => (
          <FilterDropdown {...props}>
            <Input />
          </FilterDropdown>
        )}
      />
      <Table.Column
        dataIndex="lastName"
        key="lastName"
        title="Фамилия"
        render={(value) => <TextField value={value} />}
        defaultSortOrder={getDefaultSortOrder('lastName', sorter)}
        sorter
        filterDropdown={(props: FilterDropdownProps) => (
          <FilterDropdown {...props}>
            <Input />
          </FilterDropdown>
        )}
      />
      <Table.Column
        dataIndex="firstName"
        key="firstName"
        title="Имя"
        render={(value) => (
          <TextField style={{ wordBreak: 'normal' }} value={value} />
        )}
        defaultSortOrder={getDefaultSortOrder('firstName', sorter)}
        sorter
      />

      <Table.Column
        dataIndex="role"
        key="role"
        title="Роль"
        render={(value) => <TagField value={value} />}
        defaultSortOrder={getDefaultSortOrder('role', sorter)}
        sorter
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              style={{ minWidth: 200 }}
              mode="multiple"
              placeholder="Выберите роли"
              options={Object.values(Role).map((status) => ({
                label: status,
                value: status,
              }))}
            />
          </FilterDropdown>
        )}
      />
      <Table.Column
        dataIndex="createdAt"
        key="createdAt"
        title="Создан"
        render={(value) => (
          <DateField value={value} format={'DD.MM.YYYY, HH:mm'} />
        )}
        defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
        sorter
      />
      <Table.Column
        key="action"
        title="Функции"
        render={(_, value: any) => (
          <>
            <DeleteButton recordItemId={value.uuid} hideText size="small" />
          </>
        )}
      />
    </Table> */}
  //{' '}
</List>;
