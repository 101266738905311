import {
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNotification,
  useShow,
} from '@pankod/refine-core';
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  TagField,
  ShowButton,
  NumberField,
  Spin,
  Button,
  EditButton,
  DeleteButton,
} from '@pankod/refine-antd';
import { IUser, IAgreement, IPayoutRequest, IMessage } from 'interfaces';
import React, { useContext, useState } from 'react';
import { Role } from '../../enums/role';
import { MessageStatus } from '../../enums/message.status';
import { UserContext } from 'UserProvider';
import { axiosInstance } from 'App';
import { ModalShowMsg } from 'components/messages/modalShowMsg';

export const ReviewList: React.FC<IResourceComponentsProps> = () => {
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const filters: CrudFilters = [];

  const { open: notify } = useNotification();

  const { tableProps, sorter, tableQueryResult } = useTable<IMessage>({
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    initialFilter: filters,
  });
  const { data, isLoading, refetch } = tableQueryResult;

  return (
    <>
      <List>
        <Table {...tableProps} rowKey="uuid">
          <Table.Column
            dataIndex="nameTitle"
            key="nameTitle"
            title="Данные человека"
            render={(value, record: any) => (
              <TextField
                strong={record.status === MessageStatus.UNREAD}
                value={value}
              />
            )}
            defaultSortOrder={getDefaultSortOrder('topic', sorter)}
            sorter
          />
          <Table.Column
            dataIndex="text"
            key="text"
            title="Сообщение"
            render={(value, record: any) => (
              <TextField
                strong={record.status === MessageStatus.UNREAD}
                value={value}
              />
            )}
            defaultSortOrder={getDefaultSortOrder('text', sorter)}
            sorter
          />
          <Table.Column
            dataIndex="rating"
            key="rating"
            title="Рейтинг"
            render={(value, record: any) => (
              <TextField
                strong={record.status === MessageStatus.UNREAD}
                value={value}
              />
            )}
            defaultSortOrder={getDefaultSortOrder('text', sorter)}
            sorter
          />
          <Table.Column
            dataIndex="createdAt"
            key="createdAt"
            title="Дата и время"
            render={(value, record: any) => (
              <DateField
                strong={record.status === MessageStatus.UNREAD}
                value={value}
                format={'DD.MM.YYYY, HH:mm'}
              />
            )}
            defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
            sorter
          />
          <Table.Column
            key="action"
            render={(value, record: any) => (
              <Space size={12}>
                <EditButton size="small" recordItemId={record.uuid} hideText />
                <DeleteButton
                  size="small"
                  recordItemId={record.uuid}
                  hideText
                />
              </Space>
            )}
            defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
            sorter
          />
        </Table>
      </List>
    </>
  );
};
