import React, { useState, useContext } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNavigation,
  useNotification,
} from '@pankod/refine-core';
import {
  Alert,
  AutoComplete,
  Create,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  Upload,
  UploadFile,
  useFileUploadState,
  useForm,
  Row,
  Col,
  Button,
} from '@pankod/refine-antd';
import { ArrowDownOutlined } from '@ant-design/icons';

import 'react-mde/lib/styles/css/react-mde-all.css';
import { IPicture, IPrice, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { UserType } from '../../enums/user.type';
import { axiosInstance } from '../../App';
import moment from 'moment';
import { FormRule, InputProps, InputRef } from 'antd';
import { Role } from '../../enums/role';
import InputMask from 'react-input-mask';
import { normalizeFile } from '../../utility/normalize';
import { DocumentType } from '../../enums/document.type';
import { dateStandartFormat } from 'utility/dateStandartFormat';
import dadataAddress from 'utility/dadata/address';
import { UserContext } from 'UserProvider';
import { uuidV4 } from 'utility/uuidv4';
import { PriceType } from 'enums/price.type';
const { Text } = Typography;
const { TextArea } = Input;
export const ReviewCreate: React.FC<IResourceComponentsProps> = () => {
  const { edit } = useNavigation();
  const { formProps, saveButtonProps, queryResult, form } = useForm<IPrice>({
    onMutationSuccess: (value) => edit('review', value.data.uuid),
    redirect: false,
    successNotification: {
      description: 'Отзыв создан',
      message: 'Просматривайте разделы во вкладке отзывы',
      type: 'success',
    },
    errorNotification: {
      description: 'Отзыв не создан',
      message: 'Возникла непредвиденная ошибка',
      type: 'error',
    },
  });
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;

  return (
    <Create
      headerProps={{
        subTitle: '',
      }}
      title="Создать раздел"
      saveButtonProps={{ ...saveButtonProps, children: 'Сохранить' }}
    >
      <Form {...formProps} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col xs={24} md={17}>
            <Form.Item
              label="ФИО человека"
              name="nameTitle"
              hasFeedback
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={7}>
            <Form.Item
              label="Рейтинг"
              name="rating"
              hasFeedback
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type="number" max={10} min={1} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Текст"
          name="text"
          hasFeedback
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Create>
  );
};
